import React, { useMemo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { generateStyleModifiers } from "utils/style-modifiers";
import styles from "./DeselectorEmailCapture.module.scss";
import * as Framework from "components/framework";
import extractModelId from "utils/extract-model-id";
import { addNavTracking } from "utils/analytics";
import PropTypes from "prop-types";
import * as TranslationData from "components/content/Form/TranslationFiles";
import DeselectorEmailCaptureConfig from "../Form/Templates/DeselectorEmailCapture/DeselectorEmailCaptureConfig";
import Modal from 'react-modal';

const popUpcustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
    width: 'auto',
    height: 'auto',
    border: 'none',
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 30px 0px",
    overflow: 'hidden'
  },
};

const DeselectorEmailCapture = ({
  headline,
  description,
  message,
  cmpI18n,
  consentCheckbox,
  contactCheckbox,
  formType,
  captchaFallback,
  captchaKeyName,
  captchaSiteKey,
  fields,
  locale,
  layout,
  id,
  salesforceOrganizationID,
  targetUrl,
  salesforceAPIUrl,
  categories,
  newResult,
  value,
  logoImageSrc,
  logoImageAltText,
  emailBannerImageUrl,
  emailBannerImageAltText,
  emailContactUsHeadline,
  emailContactUsUrl,
  ...props
}) => {
  const cmpThemeModifiers = generateStyleModifiers(
    props?.appliedCssClassNames,
    true
  );
  const cmpAnalytics = useMemo(() => {
    return {
      component: props?.cqType,
    };
  }, [props?.cqType]);

  useEffect(() => {
    addNavTracking(
      cmpAnalytics,
      `${extractModelId(props?.cqPath)}-description`
    );
  }, [cmpAnalytics, props?.cqPath]);


  const localeValue = locale;
  const [formError, setFormError] = useState(null);
  const {formState: { errors }} = useForm({ mode: "all" });
  const [submitError, setSubmitError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPreferenceError, setShowPreferenceError] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);

  const cmpTheme = {
    description: {
      color: "text-color--black",
      font: "font--gotham-book",
      size: "text-size--regular",
    },
    headline: {
      color: cmpThemeModifiers?.headlineTextColor || "text-color--black",
      font: cmpThemeModifiers?.headlineFont || "font--reckless-neue",
      size: "headline-text-size--x-large",
    },
    error:{
      color: "text-color--red"
    },
    popUpText: {
      color: 'text-color--black',
      font: 'font--gotham-book',
      size: 'headline-text-size--small'
    },
    popUpClsbtn: {
      style: {},
      type: 'wrapper'
    },
  };

  const config = DeselectorEmailCaptureConfig(props);

  const handleCheckbox = () => {
    setShowCheckboxError(true);
  }

  const handleEmailApi = async (data, captchaToken) => {
    setShowCheckboxError(false);
    if (!isNaN(sessionStorage.getItem("categories"))) {
      setFormError(null);
      data["captcha"] = captchaToken;
      var hrefArray = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.href);
      var hrefValue = hrefArray.filter(item => item !== null).join(', ');
      var localeArray = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.locale);
      var localeValue = localeArray.filter(item => item !== null).join(', ');  
      delete data.deselectorCheckbox;
      delete data.first_name;
      delete data.last_name;
   //   var cards = {};
     // cards.hrefValue = hrefValue;
      //cards.locale = localeValue;
      const payload = {
        href: hrefValue,
        locale:localeValue,
        logoImageSrc: logoImageSrc,
        logoImageAltText: logoImageAltText,
        emailBannerImageUrl: emailBannerImageUrl,
        emailBannerImageAltText: emailBannerImageAltText,
        emailContactUsHeadline: emailContactUsHeadline,
        emailContactUsUrl: emailContactUsUrl,
        ...data,
      };
    
    let post = JSON.stringify(payload);
    let xhr = new XMLHttpRequest();
    var url = "/content/servlets/deselectorSendEmail.sendmail.txt";
    xhr.open("POST", url, true);
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        //window.location.href = targetUrl;
        setShowPopup(true);
      }
      setSubmitError(true);
      setShowPreferenceError(false);
    };
    xhr.send(post);
  } else{
  setShowPreferenceError(true);
  }
  }

  const handleSalesforceApi = async (data, service) => {
    setShowCheckboxError(false);
    if (!isNaN(sessionStorage.getItem("categories"))) {
      var cardsFiltered = JSON.parse(sessionStorage?.newfilteredData);
      var test1 = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.serviceInterest
      );
      var additionalValue = "Multiple Services";
      var resultString = `${test1.join(";")};${additionalValue}` ;
      var industryValue = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.industry
      );
      var lineOfBusinessVal = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.lineOfBusiness
      );
      var secSrcData = JSON.parse(sessionStorage?.newfilteredData).map(
        (x) => x?.secondarySource
      );
      var secondarySrc = secSrcData.filter((item) => item !== null).join(";");
      setFormError(null);
      var payload;
      delete data.deselectorCheckbox;
      if (cardsFiltered?.length < 2) {
        payload = {
          oid: salesforceOrganizationID,
          orgid: salesforceOrganizationID,
          Service_Interests__c: test1,
          Line_Of_Business__c: lineOfBusinessVal,
          industry: industryValue,
          Secondary_Source__c: secondarySrc,
          lead_source: "Aramark.com Deselector",
          ...data,
        };
      } else {
        payload = {
          oid: salesforceOrganizationID,
          orgid: salesforceOrganizationID,
          Service_Interests__c: resultString,
          lead_source: "Aramark.com Deselector",
          ...data,
        };
      }
      const result = await service(salesforceAPIUrl, payload);
      if (result?.success) {
        //window.location.href = targetUrl;
        setShowPopup(true);
      } else {
        setFormError(result?.message);
      }
      setSubmitError(true);
      setShowPreferenceError(false);
    } else {
      setShowPreferenceError(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmission = (data, service) => {
    handleSalesforceApi(data, service);
    handleEmailApi(data, service);
  };

  return (
    <section
      className={styles.emailCaptureContainer}
      data-component="EmailCapture"
      id={extractModelId(props?.cqPath)}
      title={props?.title}
    >
      <div className={styles.content}>
        <div>
        {headline && (
          <div className={styles.headline}>
            <Framework.Title
              content={headline}
              level="2"
              theme={cmpTheme?.headline}
            />
          </div>
        )}
        {description && (
          <div className={styles.description}>
            <span
              className={
                cmpTheme.description.color +
                " " +
                cmpTheme.description.font +
                " " +
                cmpTheme.description.size
              }
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></span>
          </div>
        )}
        {!!Object.keys(errors).length && layout === "list" && (
          <div
            className={styles["errors-container"]}
            style={{
              display: "none",
            }}
            id={`${id}-errors`}
          >
            <ul className={styles["errors-list"]}>
              {Object.keys(errors).map((key, index) => (
                <li key={index} className={styles.error}>
                  <Framework.Anchor
                    href={`#${id}-${key}`}
                    inline
                    target="_self"
                    theme={{
                      style: "textError",
                      type: "link",
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={styles.formcontainer}>
          <Framework.Form
            analytics={{
              component: cmpAnalytics?.component,
              context: formType,
            }}
            buttons={config.form.buttons}
            fields={config.form.fields}
            onSubmit={(
              data,
              captchaToken
            ) =>
              data.deselectorCheckbox.length === 0 
              ? handleCheckbox() 
              : data.deselectorCheckbox.length < 2
                ? data?.deselectorCheckbox?.[0] ===
                  props?.componentProperties?.consentCheckbox
                  ? handleEmailApi(
                      data,
                      config?.service
                    )
                  : handleSalesforceApi(data, config?.service)
                : handleSubmission(data, config?.service)
            }
            captcha={!config.captcha}
            captchaSiteKey={captchaSiteKey}
          />
        </div>
        {showCheckboxError && (
          <div className={styles.submissionError}>
            <Framework.Text
              content={"Consent is Required"}
              theme={cmpTheme?.error}
            />
          </div>
        )}
        {showPreferenceError && (
          <div className={styles.submissionError}>
            <Framework.Text
              content={"Please choose your preferences in Industry Filters"}
              theme={cmpTheme?.error}
            />
          </div>
        )}
        <div>

            <Modal
              isOpen={showPopup}
              onRequestClose={showPopup}
              style={popUpcustomStyles}
              shouldCloseOnOverlayClick={false}
              overlayClassName={styles.ReactModal__Overlay}
            >

              <Framework.Button
                addtClassName={styles.closePopup}
                content={
                  <Framework.Icon icon='cross' />
                }
                label='cross'
                onClick={() => handleClosePopup()}
                theme={cmpTheme?.popUpClsbtn}
              />
              <div className={styles.popContainer}>
                <Framework.Text
                  content={"Submission Successful!"}
                  theme={cmpTheme?.popUpText}
                />
              </div>
            </Modal>
        </div>
        </div>
      </div>
    </section>
  );
};

DeselectorEmailCapture.propTypes = {
  /**
   * Form Template
   */
  formType: PropTypes.oneOf(["deselector_emailCapture"]),
  /**
   * Additional Class Name - passed from the parent Component
   * -- allows box model adjustments (margin, padding, position styles etc)
   * -- should not be used for typography or color adjustments (font family, font weight, text color etc)
   */
  addtClassName: PropTypes.string,
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string,
  }),
  /**
   * Buttons - passed from template
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      params: PropTypes.object,
    })
  ),
  /**
   * Enable/disable captcha
   */
  captcha: PropTypes.bool,
  /**
   * Aramark Captcha public key
   */
  captchaSiteKey: PropTypes.string,
  /**
   * Fields - passed from template
   */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      params: PropTypes.object,
    })
  ).isRequired,
  /**
   * Remote form validation error
   */
  formError: PropTypes.string,
  /**
   * Unique identifier for the form
   */
  id: PropTypes.string,
  /**
   * Layout
   */
  layout: PropTypes.oneOf(["custom", "list", "simple"]).isRequired,
  /**
   * Form submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Enable/disable reCaptcha
   */
  recaptcha: PropTypes.bool,
};

DeselectorEmailCapture.defaultProps = {
  formType: "deselector_emailCapture",
};

export default DeselectorEmailCapture;
